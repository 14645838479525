export default {
  logo: {
    mobile: 'logo-mobile.png',
    desktop: 'logo-desktop.png',
    alt: 'Meiser Logo',
  },
  userLoggedIn: [
    {
      label: 'Documentation',
      img: 'doc.png',
      icon: 'import_contacts',
      route: 'Documentation',
      key: 'documentation'
    },
    {
      label: 'Mes FDES',
      img: 'fdes.png',
      icon: 'description',
      route: 'UserReports',
      key: 'reports'
    },
    {
      label: 'Mon compte',
      img: 'auth-img.png',
      icon: 'account_circle',
      route: 'UserProfile',
      key: 'profile'
    },
    {
      label: 'Déconnexion',
      img: 'logout.png',
      icon: 'logout',
      route: '',
      key: 'logout'
    },
  ],
  userLoggedOut: {
    label: 'Connexion',
    img: 'auth-img.png',
    icon: 'account_circle',
    route: 'Login',
    key: 'login'
  }
};
